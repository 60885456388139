import * as React from "react";

import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import Container from "../components/ui-library/container/container";
import pageStyles from "../components/ui-library/design-tokens/common-page-content";
import { grid } from "../components/ui-library/design-tokens/grid";
import Heading from "../components/ui-library/text/heading";

const TermOfUsePage: React.FC = () => {
  return (
    <Layout>
      <SEO
        title="Term of use"
        meta={[{ name: "robots", content: "noindex" }]}
      />

      <Container css={pageStyles}>
        <Heading
          component="h1"
          size="large"
          margin={{ desktop: { bottom: grid(4) }, mobile: { bottom: grid(3) } }}
        >
          Terms of use
        </Heading>

        <p>
          These Terms of Use (the “Terms”) govern your access to and use of this
          website, coinomics.pro (the “Website”), and associated content,
          software and applications (collectively, the "Service"). These Terms
          also include our Privacy Policy, which is located at
          https://coinomics.pro/privacy-policy.
        </p>
        <p>
          The Service is administered and maintained by COINOMICS. The terms
          "we", "us" and "our" also refer to COINOMICS. The term “you” refers to
          the person visiting this Website.
        </p>
        <p>
          Please read these Terms carefully. They contain important information
          regarding your legal rights, including limitations on COINOMICS’ and
          certain third parties' liability, disclaimers of warranties and a
          submission to jurisdiction. Each time you access the Service by any
          means (for example, through the Internet or on a mobile device), you
          irrevocably agree to comply with the version of the Terms posted on
          this Website at the time you access this Website. You may not use the
          Service if you do not agree to these Terms.
        </p>

        <Heading component="h2" margin={{ top: grid(4), bottom: grid(2) }}>
          Disclaimers and Limitation of Liability
        </Heading>
        <p>
          THE CONTENT ON THE SERVICE IS STRICTLY FOR INFORMATIONAL PURPOSES.
          NOTHING ON OR IN THE SERVICE SHALL CONSTITUTE OR BE CONSTRUED AS AN
          OFFERING OF ANY CURRENCY OR ANY FINANCIAL INSTRUMENT OR AS INVESTMENT
          ADVICE OR INVESTMENT RECOMMENDATIONS (SUCH AS RECOMMENDATIONS AS TO
          WHETHER TO PURCHASE A CURRENCY OR INSTRUMENT) BY COINOMICS OR A
          RECOMMENDATION AS TO AN INVESTMENT STRATEGY BY COINOMICS. CONTENT ON
          THIS SERVICE SHOULD NOT BE CONSIDERED AS INFORMATION SUFFICIENT UPON
          WHICH TO BASE AN INVESTMENT STRATEGY. NO CONTENT ON THE SERVICE IS
          TAILORED TO THE SPECIFIC NEEDS OF ANY INDIVIDUAL, ENTITY OR GROUP OF
          INDIVIDUALS. COINOMICS EXPRESSES NO OPINION AS TO THE FUTURE OR
          EXPECTED VALUE OF ANY CURRENCY, SECURITY OR OTHER INTEREST. COINOMICS
          DOES NOT EXPLICITLY OR IMPLICITLY RECOMMEND OR SUGGEST ANY INVESTMENT
          STRATEGY OF ANY KIND. CONTENT ON THE SERVICE MAY NOT BE USED AS A
          BASIS FOR ANY FINANCIAL OR OTHER PRODUCT WITHOUT THE EXPRESS PRIOR
          WRITTEN CONSENT OF COINOMICS.
        </p>
        <p>
          The Content provided on the Service is submitted to COINOMICS by
          unrelated third-party providers. COINOMICS does not review all Content
          for accuracy, does not review Content for completeness or reliability,
          and does not warrant or guarantee the accuracy, completeness,
          reliability or any other aspect of any Content.
        </p>
        <p>
          THE SERVICE AND THE CONTENT PUBLISHED WITHIN THE SERVICE MAY INCLUDE
          INACCURACIES OR ERRORS. COINOMICS DOES NOT GUARANTEE THE ACCURACY,
          COMPLETENESS, TIMELINESS, SECURITY, AVAILABILITY OR INTEGRITY OF THE
          SERVICE OR ANY CONTENT, AND DISCLAIMS ALL LIABILITY FOR ANY ERRORS OR
          OTHER INACCURACIES RELATING TO THE SERVICE OR THE CONTENT. COINOMICS
          MAKES NO REPRESENTATIONS ABOUT THE SUITABILITY OF THE SERVICE OR ANY
          CONTENT, OR THAT THE SERVICE OR CONTENT WILL BE UNINTERRUPTED OR
          OPERATE IN COMBINATION WITH ANY SOFTWARE, SERVICE, SYSTEM OR OTHER
          DATA OR INFORMATION. THE SERVICE AND ALL CONTENT ARE PROVIDED “AS IS”
          WITHOUT WARRANTY OF ANY KIND. COINOMICS DISCLAIMS ALL WARRANTIES AND
          CONDITIONS THAT THIS SERVICE, ITS SERVERS, OR ANY EMAIL SENT FROM
          COINOMICS, ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. COINOMICS
          HEREBY DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH REGARD TO THE
          SERVICE AND THE CONTENT, INCLUDING ALL IMPLIED WARRANTIES AND
          CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
          TITLE, AND NONINFRINGEMENT.
        </p>
        <p>
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, COINOMICS ASSUMES
          NO LIABILITY OR RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR
          INACCURACIES OF ANY CONTENT; (II) PERSONAL INJURY OR PROPERTY DAMAGE,
          OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO OR USE OF THE
          SERVICE; (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SERVERS AND/OR
          ANY AND ALL INFORMATION STORED THEREIN; (IV) ANY INTERRUPTION OR
          CESSATION OF TRANSMISSION TO OR FROM THE SERVICE; (V) ANY BUGS,
          VIRUSES, TROJAN HORSES, OR THE LIKE THAT MAY BE TRANSMITTED TO OR
          THROUGH THE SERVICE BY ANY THIRD PARTY; (VI) ANY ERRORS OR OMISSIONS
          IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE INCURRED AS A RESULT OF THE
          USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE
          THROUGH THE SERVICE; AND/OR (VII) THE DEFAMATORY, OFFENSIVE, OR
          ILLEGAL CONDUCT OF ANY THIRD PARTY.
        </p>
        <p>
          IN NO EVENT SHALL COINOMICS (AND ITS RESPECTIVE OFFICERS, DIRECTORS,
          EMPLOYEES, MEMBERS, AGENTS, AND AFFILIATES) BE LIABLE FOR ANY DIRECT,
          INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES OR
          DAMAGES FOR LOST PROFITS, ARISING OUT OF, OR IN ANY WAY CONNECTED
          WITH, YOUR ACCESS TO OR USE OF THE SERVICE OR WITH THE DELAY OR
          INABILITY TO ACCESS, DISPLAY, OR USE THE SERVICE; ANY COMPUTER
          VIRUSES, INFORMATION, SOFTWARE, LINKED SERVICES, PRODUCTS, AND
          SERVICES OBTAINED THROUGH THE SERVICE; OR OTHERWISE ARISING OUT OF THE
          ACCESS TO OR USE OF THE SERVICE WHETHER BASED ON A THEORY OF
          NEGLIGENCE, CONTRACT, TORT, STRICT LIABILITY, OR OTHERWISE, AND EVEN
          IF COINOMICS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN
          NO EVENT SHALL COINOMICS (AND ITS RESPECTIVE OFFICERS, DIRECTORS,
          EMPLOYEES, MEMBERS, AGENTS, AND AFFILIATES) BE LIABLE TO YOU FOR ANY
          CLAIMS, PROCEEDINGS, LIABILITIES, OBLIGATIONS, DAMAGES, LOSSES OR
          COSTS IN EXCESS OF $100.00. THE LIMITATION OF LIABILITY REFLECTS THE
          ALLOCATION OF RISK BETWEEN THE PARTIES. THE LIMITATIONS SPECIFIED IN
          THIS SECTION WILL SURVIVE AND APPLY EVEN IF ANY LIMITED REMEDY
          SPECIFIED IN THESE TERMS IS FOUND TO HAVE FAILED OF ITS ESSENTIAL
          PURPOSE. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF
          INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS OR
          EXCLUSIONS MAY NOT APPLY TO YOU. THIS AGREEMENT GIVES YOU SPECIFIC
          LEGAL RIGHTS, AND YOU MAY ALSO HAVE OTHER RIGHTS WHICH VARY FROM STATE
          TO STATE. THE DISCLAIMERS, EXCLUSIONS, AND LIMITATIONS OF LIABILITY
          UNDER THIS AGREEMENT WILL NOT APPLY TO THE EXTENT PROHIBITED BY
          APPLICABLE LAW.
        </p>

        <Heading component="h2" margin={{ top: grid(4), bottom: grid(2) }}>
          Disclaimers and Limitation of Liability
        </Heading>
        <p>
          Except to the extent prohibited under applicable law, you shall
          indemnify, defend and hold harmless COINOMICS and its members,
          managers, directors, officers, employees, partners, consultants,
          contractors, service providers, agents, successors and assigns from
          and against any and all suits, actions, proceedings and claims by
          third parties (whether threatened or actual), and all losses,
          liabilities, damages, judgments, costs and expenses (including
          reasonable attorneys' fees) arising out of, relating to or in
          connection with: (i) your use (or misuse) of and access to the Service
          or Content; (ii) your violation of any of these Terms; (iii) your
          violation of any applicable law, rule or regulation; (d) any claim
          that any information provided by you to COINOMICS in connection with
          the Website, including the Content, caused damage to, infringed upon,
          misappropriated or otherwise violated the rights of any third party,
          including infringement, misappropriation or other violation of
          third-party intellectual property rights, or violation of any right of
          privacy or publicity; and/or (iv) any dispute that you have with any
          third party relating to or in connection with the Service or Content.
          COINOMICS reserves the right, at its own cost, to assume the exclusive
          defense and control of any matter otherwise subject to indemnification
          by you, in which event you will fully cooperate with COINOMICS in
          asserting any available defenses and in the conduct of such defense.
        </p>

        <Heading component="h2" margin={{ top: grid(4), bottom: grid(2) }}>
          Third Party Websites
        </Heading>
        <p>
          The Service may contain links to third-party websites. Your use of all
          links to third-party websites is at your own risk. We do not monitor
          or have any control over, and make no claim or representation
          regarding third-party websites. To the extent such links are provided
          by us, they are provided only as a convenience, and a link to a
          third-party websites does not imply our endorsement, adoption or
          sponsorship of, or affiliation with, such third-party websites.
        </p>
        <p>
          When you leave the Website, whether via a link contained on the
          Website or through the use of your web browser or other navigational
          tool, the information you view is not provided by us. Our terms and
          policies do not govern your use of third-party websites. We are not
          responsible for, have no control over and do not monitor or review the
          content of any other website. A link to a third-party website does not
          imply sponsorship, approval, affiliation or endorsement by COINOMICS
          of the linked third-party website or of that third party's products or
          services.
        </p>
        <p>
          YOU AGREE THAT COINOMICS WILL NOT, UNDER ANY CIRCUMSTANCES, BE
          RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY GOODS,
          SERVICES, INFORMATION, RESOURCES AND/OR CONTENT AVAILABLE ON OR
          THROUGH ANY THIRD-PARTY WEBSITE OR SERVICES, FOR ANY DEALINGS OR
          COMMUNICATIONS YOU MAY HAVE WITH THIRD PARTIES, OR FOR ANY HARM,
          DAMAGES OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN CONNECTION
          WITH ANY OF THE FOREGOING OR YOUR USE OF OR RELIANCE ON THE MATERIALS
          OR THE CONTENT OR BUSINESS PRACTICES OF ANY THIRD PARTY.
        </p>

        <Heading component="h2" margin={{ top: grid(4), bottom: grid(2) }}>
          Modification of the Website
        </Heading>
        <p>
          At any time and in COINOMICS's sole discretion, COINOMICS may (in
          whole or in part) modify, suspend or discontinue the Service and/or
          any Content without notice, for any reason. We shall have no liability
          to you or to any third party for any such modification, suspension or
          discontinuance.
        </p>

        <Heading component="h2" margin={{ top: grid(4), bottom: grid(2) }}>
          Changes to these Terms
        </Heading>
        <p>
          We may revise these Terms from time to time, without prior notice. You
          are bound by any changes to these Terms upon our posting of such
          changes on the Service. You should check these Terms often to make
          certain that you are aware of the most current Terms.
        </p>

        <Heading component="h2" margin={{ top: grid(4), bottom: grid(2) }}>
          Minors
        </Heading>
        <p>
          Our products and services are targeted for adults and business
          entities. Our Site, our Site Services, our Content and our Updates are
          therefore not meant for persons who may be defined as minors under
          applicable law. However, it is not possible for our Company to
          determine whether any user of our Site or Site Services is a minor. If
          you are a minor, please discontinue the use of our Site and all Site
          Services immediately. It is the responsibility of parents and legal
          guardians to monitor whether a minor is using our Site or Site
          Services.
        </p>

        <Heading component="h2" margin={{ top: grid(4), bottom: grid(2) }}>
          Enforcement
        </Heading>
        <p>
          The remedies available to COINOMICS in these Terms are cumulative and
          in addition to any others available to COINOMICS. COINOMICS may seek
          all remedies available to it at law and in equity for any violation of
          these Terms. COINOMICS may suspend, terminate or block your access to
          the Service (in whole or in part) for any violation or suspected
          violation as we determine, without notice to you. Your violation of
          these Terms shall be considered a breach of contract.
        </p>
        <p>
          We reserve the right, but do not assume any obligation, to investigate
          any suspected violation of these Terms or any misuse of the Service.
          In addition, we further reserve the right to report any activity, data
          or persons to, and otherwise cooperate with: (i) law enforcement
          authorities; (ii) financial regulators, including the U.S. Securities
          and Exchange Commission (SEC); (iii) system administrators at Internet
          service providers, networks or computing facilities; and (iv)
          providers and/or third-party vendors if we suspect that you have
          violated these Terms or any law, rule or regulation. You acknowledge
          that such reporting or cooperation may include, without limitation,
          providing information relating to you and/or your use of the Service,
          including without limitation your email address, IP address or other
          identifying information, to law enforcement authorities, financial
          regulators, third-party providers, vendors or system administrators.
          Further, we may disclose any information we think necessary to comply
          with applicable law, regulation, subpoena or other legal process or
          governmental or regulatory request.
        </p>

        <Heading component="h2" margin={{ top: grid(4), bottom: grid(2) }}>
          General
        </Heading>
        <p>
          These Terms (and any other terms or agreements referenced herein,
          including our Privacy Policy, which is located at
          https://coinomics.pro/privacy), constitute the entire agreement
          between you and COINOMICS relating to your use of the Service and
          supersede all prior or contemporaneous communications, whether
          electronic, oral or written, between you and COINOMICS with respect to
          the Service. A printed version of these Terms and of any notice given
          in electronic form shall be admissible in judicial or administrative
          proceedings based upon or relating to these Terms to the same extent
          and subject to the same conditions as other business documents and
          records originally generated and maintained in printed form.
        </p>
        <p>
          You agree that no joint venture, agency, partnership, or employment
          relationship exists between you and COINOMICS and/or its affiliates as
          a result of these Terms or use of the Service.
        </p>
        <p>
          In no event shall COINOMICS be responsible or liable for any failure
          or delay in the performance of its obligations hereunder arising out
          of or caused by, directly or indirectly, forces beyond its control,
          including, without limitation, strikes, work stoppages, accidents,
          acts of war or terrorism, civil or military disturbances, nuclear or
          natural catastrophes or acts of God, and interruptions, loss or
          malfunctions of utilities, communications or computer (software and
          hardware) services.
        </p>
        <p>
          Our performance under these Terms is subject to existing laws and
          legal process, and nothing contained in these Terms limits our right
          to comply with law enforcement or other governmental or legal requests
          or requirements relating to your use of the Service or information
          provided to, or gathered by, us with respect to such use. To the
          extent allowed by applicable law, you agree that you will bring any
          claim or cause of action arising from or relating to your access or
          use of this Service within two (2) years from the date on which such
          claim or action arose or accrued or such claim or cause of action will
          be irrevocably waived.
        </p>
        <p>
          These Terms, and any rights and licenses granted hereunder, may not be
          transferred or assigned by you, but may be assigned by COINOMICS
          without restriction. Any attempted transfer or assignment by you in
          violation hereof shall be null and void.
        </p>
        <p>
          If any provision of these Terms, including, but not limited to, the
          warranty disclaimers and limitations of liability set forth above, is
          determined to be invalid or unenforceable under applicable law, the
          invalid or unenforceable provisions in these Terms shall be deemed
          superseded by valid and enforceable provisions that, to the extent
          possible, fulfill the business purposes and intent of such invalid and
          unenforceable provisions.
        </p>
        <p>
          In addition to and without limiting the preceding paragraph, some U.S.
          states and foreign countries may provide rights in addition to those
          provided in the above "Disclaimers and Limitation of Liability"
          section or do not allow the exclusion or limitation of implied
          warranties or liability for incidental or consequential damages.
          Therefore, the limitations set forth in the above Disclaimer and
          Limitation of Liability section may not apply to you in whole or in
          part or there may be state or country specific provisions that
          supersede such limitations in whole or in part. Any provision of the
          above Disclaimer and Limitation of Liability section that is declared
          invalid shall be deemed severable and shall not affect the validity or
          enforceability of the remainder.
        </p>
        <p>
          Any heading or section title contained herein is for convenience of
          reference only and shall not affect the meaning or interpretation of
          these Terms. The terms "include" and "including" are deemed to include
          the phrase "without limitation" immediately thereafter.
        </p>
      </Container>
    </Layout>
  );
};

export default TermOfUsePage;
